import "./App.css";
import { Login } from "./views/LoginPage/Login";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Dashboard } from "./views/Dashboard/Dashboard";
import { Charts } from "./views/Dashboard/Charts/Charts";
import { Moptro } from "./views/Moptro/Moptro";
import { ReportBody } from "./views/Reports/ReportBody";
import Layout from "./Layout";
import Signup from "./Signup";
import { Navigate } from "react-router-dom";

//PI_2024_08 Release - W.C.V 0.1.12
//Supported Backend Function - PI_2024_08 & PI_2024_04(With Bug in MOPTro Uptime)
function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/app/signup" element={<Signup />} />
          <Route path="" element={<Navigate to="/" replace />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          {/* <Route exact path="*" element={<Login />} /> */}
          {/* <Route exact path="/app" element={<Login />} /> */}
          <Route exact path="/app/Dashboard" element={<Dashboard />} />
          {/* <Route exact path="/app/dashboard/Overview" element={<Dashboard />} /> */}
          <Route exact path="/app/Charts" element={<Charts />} />
          <Route exact path="/app/Report" element={<ReportBody />} />
          <Route exact path="/app/moptro" element={<Moptro />} />
          {/* <Route exact path="/forgot-password" element={<ForgotPassword />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
