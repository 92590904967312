import React, { useState, useEffect } from "react";

import "./loginBody.css";
import analticsLogo from "../../assets/AnalyticsLogo.svg";
import visiblePassword from "../../assets/PasswordVisible.svg";
import invisiblePassword from "../../assets/PasswordInvisible.svg";
import axios from "axios";
import { Header } from "./Header";
import { Products } from "./Products";
import { LoginFooter } from "./loginFooter/loginFooter";
import { useNavigate } from "react-router-dom";
import { setID, setName, setCustomer } from "../../redux/slice/userSlice";
import { setAuth } from "../../redux/slice/authSlice";
import Loader from "../Loader/Loader"

//importing api
// import { sendRequest } from "../../service/appUtils";
import { useDispatch, useSelector } from "react-redux";
import fetchApiData from "../../service/appUtils";

export const  LoginBody = () => {

  const[loading,setLoading]=useState(true)

  useEffect(() => {
    const config = {
      css_url: "/app/embedded_signin_providers_only.css",
      signin_providers_only: true,
      // is_customize_forgot_password: false,
      // forgot_password_id: "login",
      forgot_password_css_url: "/app/fpwd.css",
    };
    const call= window.catalyst.auth.signIn("login", config);
    window.catalyst.auth.signIn("login", config);
    setLoading(false)
  }, []);
  // Using Dispatch function here
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('admin');
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  // let loggedIn = false;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(invisiblePassword);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    setPasswordIcon(passwordVisible ? invisiblePassword : visiblePassword);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    var body = { loginId: username, password: password };
    const url =
      (process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_DEV
        : process.env.REACT_APP_API_BASE_URL_PROD) + "login";
    const key =
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_KEY_DEV
        : process.env.REACT_APP_API_KEY_PROD;
    axios.defaults.headers["ZCFKEY"] = key;
    let response = await axios
      .post(url, body)
      .then(function (response) {
        if (response.data.status === 200) {
          if (response.data.contact_number) {
            dispatch(setID(response.data.contact_number));
            dispatch(setName(response.data.name));
            dispatch(setAuth(true));
            navigate("/app/dashboard");
            localStorage.setItem("authenticated", true);
            //loggedIn = true;
          } else {
            // dispatch(setID("demo"));
            // dispatch(setName("demo"));
            // dispatch(setAuth(true));
            //navigate("/app");
            // dispatch(setID(response.data.contact_number));
            localStorage.setItem("authenticated", true);
            alert("WRONG CREDENTIALS");
          }
          setLoader(false);
          // navigate("/app/dashboard");
        } else {
          alert("Wrong Email or Password!");
          setLoader(false);
        }
      })
      .catch(function (error) {
        alert("Wrong Email or Password!");
        setLoader(false);
      });
  };
  return (
    <div className="main-body">
      <Header />
      {loading && (
        <Loader/>
      )}
      <div className="login-window-container">
        <div className="login-window">
          <div className="logo-container">
            <img
              src={analticsLogo}
              className="analytics-logo"
              alt="Analytics Logo"
            />
          </div>
          <div className="verticle-line"></div>
          <div className="signup-container">
            <div className="signup">
              <div className="signup-title">Sign In</div>
              <input
                type="text"
                placeholder="Email ID"
                onChange={(e) => setUsername(e.target.value)}
                className="signup-user"
              />
              <div className="password-container">
                <div className="password-text">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div>
                  <span
                    className="password-visibility"
                    onClick={togglePasswordVisibility}
                  >
                    <img src={passwordIcon} alt="togglePassword" />
                  </span>
                </div>
              </div>
              <p
                className="forgot-password"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </p>
            </div>

              <div className="zoho-login-container">
              <button className="login-btn" onClick={handleSubmit}>
                Log in
              </button>
              <div className="signup-options">
                or
                </div>
              <div id="login"></div>
            </div>
            

            <div className="signup-footer">  
              <p>Privacy</p>
              <p>Terms & Conditions</p>
              <p>Help</p>
            </div>
          </div>
        </div>
      </div>
      <Products />
      <LoginFooter />
    </div>
  );
};
